import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './styles.css';

function Login({ setUserType }) {
  const [emailOrDni, setEmailOrDni] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Detectar studioId desde subdominio o usar 'default'
    const host = window.location.hostname;
    const parts = host.split('.');
    const studioId = parts.length > 2 ? parts[0] : 'default';

    if (emailOrDni === 'duenio@reservalo.com' && password === '123456') {
      localStorage.setItem('userType', 'duenio');
      localStorage.setItem('studioId', studioId);
      setUserType('duenio');
      navigate('/calendario');
    } else {
      try {
        const res = await fetch('/api/login-alumno', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            usuario: emailOrDni,
            contrasena: password,
            studioId: studioId
          })
        });

        if (!res.ok) throw new Error('Credenciales inválidas');

        const data = await res.json();
        localStorage.setItem('userType', 'alumno');
        localStorage.setItem('id', data.id);
        localStorage.setItem('nombre', data.nombre);
        localStorage.setItem('studioId', studioId);
        setUserType('alumno');
        navigate('/alumno');
      } catch (err) {
        setError('Credenciales incorrectas');
      }
    }
  };

  return (
    <div className="login-container">
      <h2>Iniciar sesión</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Usuario"
          value={emailOrDni}
          onChange={(e) => setEmailOrDni(e.target.value)}
          style={{
            width: '100%',
            padding: '12px',
            marginBottom: '18px',
            border: '1px solid #ccc',
            borderRadius: '8px',
          }}
        />
        <input
          type="password"
          placeholder="Contraseña"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          style={{
            width: '100%',
            padding: '12px',
            marginBottom: '18px',
            border: '1px solid #ccc',
            borderRadius: '8px',
            backgroundColor: '#f9f9f9'
          }}
        />
        <button type="submit" className="boton-beige">Ingresar</button>
      </form>
      {error && <p className="error-message">{error}</p>}
    </div>
  );
}

export default Login;
