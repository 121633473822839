import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles.css';

const AlumnoPanel = () => {
  const [vista, setVista] = useState('inicio');
  const [clases, setClases] = useState([]);
  const nombre = localStorage.getItem('nombre') || 'Alumno';
  const id = parseInt(localStorage.getItem('id'));
  const navigate = useNavigate();

  // Detectar studioId desde el hostname (subdominio) o usar "default"
  const host = window.location.hostname;
  const parts = host.split('.');
  const studioId = parts.length > 2 ? parts[0] : 'default';

  useEffect(() => {
    fetch('/api/clases', {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    })
      .then(res => res.json())
      .then(data => setClases(data))
      .catch(err => console.error('Error al cargar clases:', err));
  }, []);

  const ahora = new Date();

  const clasesFuturas = clases
    .filter(c => new Date(c.fecha) >= ahora)
    .sort((a, b) => new Date(a.fecha) - new Date(b.fecha));

  const clasesPasadas = clases
    .filter(c => new Date(c.fecha) < ahora && c.participantes.includes(id))
    .sort((a, b) => new Date(b.fecha) - new Date(a.fecha));

  const clasesFuturasAlumno = clasesFuturas.filter(c => c.participantes.includes(id));

  const desagendarClase = (claseId) => {
    const clase = clases.find(c => c.id === claseId);
    const actualizada = {
      ...clase,
      participantes: clase.participantes.filter(pid => pid !== id)
    };

    fetch(`/api/clases/${claseId}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ ...actualizada, studioId })
    })
      .then(res => res.json())
      .then(() => {
        setClases(clases.map(c => c.id === claseId ? actualizada : c));
      })
      .catch(err => console.error('Error al desagendar la clase:', err));
  };

  const cantidadRecuperacionesEsteMes = clasesFuturas.filter(c => {
    const fecha = new Date(c.fecha);
    const esEsteMes = fecha.getMonth() === ahora.getMonth() && fecha.getFullYear() === ahora.getFullYear();
    return esEsteMes && c.participantes.includes(id);
  }).length;

  const agendarClase = (claseId) => {
    if (cantidadRecuperacionesEsteMes >= 2) {
      alert('Solo podés recuperar hasta 2 clases por mes.');
      return;
    }

    const clase = clases.find(c => c.id === claseId);
    if (!clase || clase.participantes.includes(id) || clase.participantes.length >= clase.cupos) return;

    const actualizada = {
      ...clase,
      participantes: [...clase.participantes, id]
    };

    fetch(`/api/clases/${claseId}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ ...actualizada, studioId })
    })
      .then(res => res.json())
      .then(() => {
        setClases(clases.map(c => c.id === claseId ? actualizada : c));
      })
      .catch(err => console.error('Error al agendar la clase:', err));
  };

  const generarLinkInasistencia = (fecha, titulo) => {
    const texto = `Hola, lamentablemente faltaré a mi clase de ${titulo} el ${new Date(fecha).toLocaleString()}`;
    return `https://wa.me/?text=${encodeURIComponent(texto)}`;
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate('/');
  };

  return (
    <div className="alumno-panel">
      <h2>👋 ¡Hola, {nombre}!</h2>

      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '20px' }}>
        <button onClick={handleLogout}>Cerrar sesión</button>
      </div>

      <div style={{ marginBottom: '20px' }}>
        <button onClick={() => setVista('recuperar')}>📅 Recuperar clase</button>
        <button onClick={() => setVista('falta')} style={{ marginLeft: '10px' }}>🚫 Aviso falta</button>
      </div>

      {vista === 'recuperar' && (
        <div>
          <h3>Clases con cupos disponibles</h3>
          {clasesFuturas.length === 0 ? (
            <p>No hay clases con cupos.</p>
          ) : (
            <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
              {clasesFuturas.map(clase => {
                const hayCupo = clase.participantes.length < clase.cupos;
                const yaInscripto = clase.participantes.includes(id);
                if (!hayCupo || yaInscripto) return null;

                const fecha = new Date(clase.fecha);
                const fechaStr = fecha.toLocaleDateString();
                const horaStr = fecha.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

                return (
                  <div key={clase.id} style={{
                    border: '1px solid #ddd',
                    borderRadius: '10px',
                    padding: '12px',
                    background: '#f9f6ef',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}>
                    <div>
                      <strong>{fechaStr}</strong> – {horaStr}<br />
                      🪑 {clase.cupos - clase.participantes.length} lugares disponibles
                    </div>
                    <button onClick={() => agendarClase(clase.id)}>Agendarme</button>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}

      {vista === 'falta' && (
        <div>
          <h3>Clases futuras donde estás inscripto</h3>
          {clasesFuturasAlumno.length === 0 ? (
            <p>No estás inscripto en ninguna clase futura.</p>
          ) : (
            <ul>
              {clasesFuturasAlumno.map(clase => (
                <li key={clase.id}>
                  {new Date(clase.fecha).toLocaleString()} – {clase.titulo}
                  <button onClick={() => desagendarClase(clase.id)} style={{ marginLeft: '10px' }}>
                    Marcar como falta
                  </button>
                </li>
              ))}
            </ul>
          )}
        </div>
      )}

      <section style={{ marginTop: '30px' }}>
        <h3>📜 Historial de clases:</h3>
        {clasesPasadas.length === 0 ? (
          <p>No hay clases anteriores.</p>
        ) : (
          <ul>
            {clasesPasadas.map(clase => (
              <li key={clase.id}>
                <strong>{clase.titulo}</strong> – {new Date(clase.fecha).toLocaleString()}
              </li>
            ))}
          </ul>
        )}
      </section>
    </div>
  );
};

export default AlumnoPanel;
