import React from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';

const Navbar = () => {
  return (
    <div className="navbar">
      <Link to="/calendario">📅 Calendario</Link>
      <Link to="/personas">👥 Personas</Link>
    </div>
  );
};

export default Navbar;
