// ... (el resto del código hasta antes del return no cambia)
import React, { useState, useEffect } from 'react';
import './Calendar.css';
import { useNavigate } from 'react-router-dom';

const Calendar = () => {
  const [semanaOffset, setSemanaOffset] = useState(0);
  const [clases, setClases] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedDateTime, setSelectedDateTime] = useState(null);
  const [tituloClase, setTituloClase] = useState('');
  const [cupos, setCupos] = useState(1);
  const [personas, setPersonas] = useState([]);
  const [participantes, setParticipantes] = useState([]);
  const [inputParticipantesTexto, setInputParticipantesTexto] = useState('');
  const [editandoClaseId, setEditandoClaseId] = useState(null);
  const [repetirSemanalmente, setRepetirSemanalmente] = useState(false);

  const navigate = useNavigate();
  const host = window.location.hostname;
  const parts = host.split('.');
  const studioId = parts.length > 2 ? parts[0] : 'default';
  const apiUrl = '/api';

  const handleLogout = () => {
    localStorage.clear();
    navigate('/');
  };

  useEffect(() => {
    fetch(`${apiUrl}/personas`).then(res => res.json()).then(setPersonas);
    fetch(`${apiUrl}/clases`).then(res => res.json()).then(setClases);
  }, []);

  const obtenerSemana = () => {
    const hoy = new Date();
    const dia = hoy.getDay();
    const diferencia = hoy.getDate() - dia + (dia === 0 ? -6 : 1) + semanaOffset * 7;
    const lunes = new Date(hoy.setDate(diferencia));
    return Array.from({ length: 6 }, (_, i) => {
      const fecha = new Date(lunes);
      fecha.setDate(lunes.getDate() + i);
      return fecha;
    });
  };

  const mismaHora = (a, b) => (
    a.getFullYear() === b.getFullYear() &&
    a.getMonth() === b.getMonth() &&
    a.getDate() === b.getDate() &&
    a.getHours() === b.getHours() &&
    a.getMinutes() === b.getMinutes()
  );

  const dias = obtenerSemana();
  const horas = Array.from({ length: 17 }, (_, i) => 6 + i);

  const handleCellClick = (dia, hora) => {
    const fecha = new Date(dia);
    fecha.setHours(hora, 0, 0, 0);
    setSelectedDateTime(fecha);
    setTituloClase('');
    setCupos(1);
    setParticipantes([]);
    setEditandoClaseId(null);

    const claseExistente = clases.find(c => mismaHora(new Date(c.fecha), fecha));
    if (claseExistente) {
      setTituloClase(claseExistente.titulo);
      setCupos(claseExistente.cupos);
      setParticipantes(claseExistente.participantes);
      setEditandoClaseId(claseExistente.id);
      setInputParticipantesTexto(
        claseExistente.participantes
          .map(id => personas.find(p => p.id === id)?.nombre || '')
          .join(', ')
      );
    } else {
      setInputParticipantesTexto('');
    }

    setModalVisible(true);
  };

  const guardarClase = () => {
    if (personas.length === 0) {
      alert('Aún se están cargando los datos de las personas. Por favor, esperá unos segundos.');
      return;
    }

    const nombres = inputParticipantesTexto
      .split(',')
      .map(n => n.trim().toLowerCase().normalize('NFD').replace(/[̀-ͯ]/g, ''));

    const ids = personas
      .filter(p => typeof p.nombre === 'string' && p.nombre.trim() !== '')
      .filter(p => nombres.some(nombre => p.nombre.toLowerCase().normalize('NFD').replace(/[̀-ͯ]/g, '').includes(nombre)))
      .map(p => p.id);

    const nuevasClases = editandoClaseId
      ? [{ id: editandoClaseId, titulo: tituloClase, fecha: selectedDateTime, cupos, participantes: ids, studioId }]
      : repetirSemanalmente
        ? Array.from({ length: 4 }, (_, i) => {
            const fechaRepetida = new Date(selectedDateTime);
            fechaRepetida.setDate(fechaRepetida.getDate() + 7 * i);
            return { titulo: tituloClase, fecha: fechaRepetida, cupos, participantes: ids, studioId };
          })
        : [{ titulo: tituloClase, fecha: selectedDateTime, cupos, participantes: ids, studioId }];

    Promise.all(
      nuevasClases.map(clase => {
        const metodo = clase.id ? 'PUT' : 'POST';
        const url = clase.id ? `${apiUrl}/clases/${clase.id}` : `${apiUrl}/clases`;
        return fetch(url, {
          method: metodo,
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(clase),
        }).then(res => res.json());
      })
    ).then(() => {
      fetch(`${apiUrl}/clases`).then(res => res.json()).then(setClases);
      setModalVisible(false);
      setTituloClase('');
      setCupos(1);
      setParticipantes([]);
      setInputParticipantesTexto('');
      setEditandoClaseId(null);
      setRepetirSemanalmente(false);
    }).catch(err => console.error('Error al guardar clase:', err));
  };

  const borrarClase = () => {
    if (!editandoClaseId) return;
    if (!window.confirm("¿Querés borrar esta clase? Esta acción no se puede deshacer.")) return;

    fetch(`${apiUrl}/clases/${editandoClaseId}`, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ studioId })
    })
      .then(res => res.ok ? res.json() : Promise.reject('Error al borrar clase'))
      .then(() => fetch(`${apiUrl}/clases`).then(res => res.json()).then(setClases))
      .then(() => {
        setModalVisible(false);
        setTituloClase('');
        setCupos(1);
        setParticipantes([]);
        setInputParticipantesTexto('');
        setEditandoClaseId(null);
        setRepetirSemanalmente(false);
      })
      .catch(err => alert('Hubo un error al intentar borrar la clase.'));
  };

  return (
    <div className="calendar-container">
      <h2>Calendario de Clases</h2>
      <button onClick={handleLogout} style={{ float: 'right' }}>Cerrar sesión</button>

      <div className="calendar-controls">
        <button onClick={() => setSemanaOffset(semanaOffset - 1)}>← Semana anterior</button>
        <button onClick={() => setSemanaOffset(0)}>Hoy</button>
        <button onClick={() => setSemanaOffset(semanaOffset + 1)}>Semana siguiente →</button>
      </div>

      <div className="calendar-grid">
        <div className="calendar-header"></div>
        {dias.map((d, i) => (
          <div key={i} className="calendar-header">
            {d.toLocaleDateString('es-AR', { weekday: 'short', day: 'numeric' })}
          </div>
        ))}

        {horas.map(h => (
          <React.Fragment key={h}>
            <div className="calendar-hour">{h}:00</div>
            {dias.map((d, i) => {
              const fechaHora = new Date(d);
              const fechaFinal = new Date(fechaHora);
              fechaFinal.setHours(h, 0, 0, 0);
              const clase = clases.find(c => mismaHora(new Date(c.fecha), fechaFinal));
              return (
                <div key={i} className="calendar-cell" onClick={() => handleCellClick(fechaFinal, h)}>
                  {clase && (
                    <div className="clase-info">
                      <strong>{clase.titulo}</strong><br />
                      {clase.participantes.length} / {clase.cupos}
                    </div>
                  )}
                </div>
              );
            })}
          </React.Fragment>
        ))}
      </div>

      {modalVisible && (
        <div className="modal-overlay">
          <div className="modal">
            <h3>{editandoClaseId ? 'Editar Clase' : 'Crear Clase'}</h3>

            <input
              className="input-full"
              value={tituloClase}
              onChange={e => setTituloClase(e.target.value)}
              placeholder="Título de la clase"
            />

            <input
              type="number"
              className="input-full"
              value={cupos}
              onChange={e => setCupos(parseInt(e.target.value))}
              placeholder="Cupos"
            />

            <textarea
              className="input-full"
              rows={2}
              value={inputParticipantesTexto}
              onChange={e => setInputParticipantesTexto(e.target.value)}
              placeholder="Participantes separados por coma"
            />

            {!editandoClaseId && (
              <label>
                <input
                  type="checkbox"
                  checked={repetirSemanalmente}
                  onChange={e => setRepetirSemanalmente(e.target.checked)}
                />
                Repetir semanalmente (4 semanas)
              </label>
            )}

            <div style={{ marginTop: '10px' }}>
              <button onClick={guardarClase}>Guardar</button>
              {editandoClaseId && <button onClick={borrarClase}>Borrar</button>}
              <button onClick={() => setModalVisible(false)}>Cancelar</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Calendar;
