// Mismo import que ya tenés
import React, { useState, useEffect } from 'react';
import './StudentsManagement.css';
import Papa from 'papaparse';

const StudentsManagement = () => {
  const [personas, setPersonas] = useState([]);
  const [usuarios, setUsuarios] = useState([]);
  const [modoEdicion, setModoEdicion] = useState(null);
  const [formEditado, setFormEditado] = useState({});
  const [busqueda, setBusqueda] = useState('');
  const [csvPreview, setCsvPreview] = useState([]);
  const [modoBorrado, setModoBorrado] = useState(false);
  const [seleccionados, setSeleccionados] = useState([]);

  const host = window.location.hostname;
  const parts = host.split('.');
  const studioId = parts.length > 2 ? parts[0] : 'default';
  const apiUrl = window.location.origin.includes('mireserva.app')
    ? '/api'
    : 'http://198.211.100.96:4001';

  useEffect(() => {
    fetch(`${apiUrl}/personas`).then(res => res.json()).then(setPersonas);
    fetch(`${apiUrl}/usuarios`).then(res => res.json()).then(setUsuarios);
  }, [apiUrl]);

  const editarFila = (id) => {
    const persona = personas.find(p => p.id === id);
    const usuario = usuarios.find(u => u.id === id);
    setModoEdicion(id);
    setFormEditado({
      nombre: persona.nombre,
      telefono: persona.telefono,
      pagaMensualidad: persona.pagaMensualidad,
      notas: persona.notas || '',
      usuario: usuario?.usuario || '',
      contrasena: usuario?.contrasena || ''
    });
  };

  const crearPersona = () => {
    const nueva = {
      nombre: formEditado.nombre || '',
      telefono: formEditado.telefono || '',
      pagaMensualidad: formEditado.pagaMensualidad || false,
      notas: formEditado.notas || '',
      usuario: formEditado.usuario || '',
      contrasena: formEditado.contrasena || '',
      studioId
    };

    fetch(`${apiUrl}/personas`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(nueva)
    })
      .then(res => {
        if (!res.ok) throw new Error('Error al guardar persona');
        return res.json();
      })
      .then(() => fetch(`${apiUrl}/personas`).then(res => res.json()).then(setPersonas))
      .catch(err => {
        console.error('Error al agregar persona:', err);
        alert('Hubo un error al agregar la persona');
      })
      .finally(() => {
        setFormEditado({});
      });
  };

  const cancelarEdicion = () => {
    setModoEdicion(null);
    setFormEditado({});
  };

  const guardarCambios = (id) => {
    const personaActualizada = {
      id,
      nombre: formEditado.nombre,
      telefono: formEditado.telefono,
      pagaMensualidad: formEditado.pagaMensualidad,
      notas: formEditado.notas,
      studioId
    };

    fetch(`${apiUrl}/personas/${id}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(personaActualizada)
    })
      .then(res => res.json())
      .then(data => {
        setPersonas(personas.map(p => (p.id === id ? data : p)));

        fetch(`${apiUrl}/usuarios`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            id,
            nombre: formEditado.nombre,
            usuario: formEditado.usuario,
            contrasena: formEditado.contrasena,
            studioId
          })
        }).then(() => {
          fetch(`${apiUrl}/usuarios`).then(res => res.json()).then(setUsuarios);
        });

        cancelarEdicion();
      });
  };

  const toggleSeleccionado = (id) => {
    setSeleccionados(prev =>
      prev.includes(id) ? prev.filter(s => s !== id) : [...prev, id]
    );
  };

  const borrarSeleccionados = () => {
    if (seleccionados.length === 0) return;
    const confirmacion = window.confirm('¿Seguro querés borrar los registros seleccionados?');
    if (!confirmacion) return;

    Promise.all(
      seleccionados.map(id =>
        fetch(`${apiUrl}/personas/${id}`, {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ studioId })
        })
      )
    ).then(() => {
      setPersonas(prev => prev.filter(p => !seleccionados.includes(p.id)));
      setSeleccionados([]);
      setModoBorrado(false);
    });
  };

  const personasFiltradas = personas.filter(p =>
    (p.nombre || '').toLowerCase().includes(busqueda.toLowerCase())
  );

  const importarDesdeCSV = (e) => {
    const archivo = e.target.files[0];
    if (!archivo) return;

    Papa.parse(archivo, {
      header: true,
      skipEmptyLines: true,
      complete: (result) => {
        const datos = result.data.map(p => ({
          nombre: p.nombre,
          telefono: p.telefono,
          pagaMensualidad: p.pagaMensualidad === 'true',
          notas: p.notas || '',
          usuario: p.usuario || '',
          contrasena: p.contrasena || '',
          studioId
        }));
        setCsvPreview(datos);
      }
    });
  };

  return (
    <div className="students-container">
      <h2>Gestión de Personas</h2>

      <h3>➕ Agregar nueva persona</h3>
      <div style={{ marginBottom: '20px' }}>
        <input placeholder="Nombre" style={{ marginRight: '10px' }} value={formEditado.nombre || ''} onChange={(e) => setFormEditado(prev => ({ ...prev, nombre: e.target.value }))} />
        <input placeholder="Teléfono" style={{ marginRight: '10px' }} value={formEditado.telefono || ''} onChange={(e) => setFormEditado(prev => ({ ...prev, telefono: e.target.value }))} />
        <label style={{ marginRight: '10px' }}>
          <input type="checkbox" checked={formEditado.pagaMensualidad || false} onChange={(e) => setFormEditado(prev => ({ ...prev, pagaMensualidad: e.target.checked }))} />
          Paga mensualidad
        </label>
        <input placeholder="Notas" style={{ marginRight: '10px' }} value={formEditado.notas || ''} onChange={(e) => setFormEditado(prev => ({ ...prev, notas: e.target.value }))} />
        <input placeholder="Usuario" style={{ marginRight: '10px' }} value={formEditado.usuario || ''} onChange={(e) => setFormEditado(prev => ({ ...prev, usuario: e.target.value }))} />
        <input placeholder="Contraseña" style={{ marginRight: '10px' }} value={formEditado.contrasena || ''} onChange={(e) => setFormEditado(prev => ({ ...prev, contrasena: e.target.value }))} />
        <button className="boton-beige" onClick={crearPersona}>Agregar persona</button>
      </div>

      <h3>📂 Importar alumnos desde CSV</h3>
      <input type="file" accept=".csv" className="boton-beige" onChange={importarDesdeCSV} />

      {csvPreview.length > 0 && (
        <>
          <ul>
            {csvPreview.map((p, i) => (
              <li key={i}>
                {p.nombre} - {p.telefono} - {p.pagaMensualidad ? 'Paga' : 'No paga'} - {p.notas}
              </li>
            ))}
          </ul>
          <button className="boton-beige" onClick={() => {}}>Cargar alumnos</button>
        </>
      )}

      <div style={{ margin: '20px 0' }}>
        <button className="boton-beige" onClick={() => setModoBorrado(!modoBorrado)}>
          {modoBorrado ? '❌ Cancelar borrado' : '🗑 Borrar registros'}
        </button>
        {modoBorrado && (
          <button
            onClick={borrarSeleccionados}
            style={{
              backgroundColor: '#e74c3c',
              color: 'white',
              border: 'none',
              padding: '8px 12px',
              borderRadius: '6px',
              marginLeft: '12px'
            }}
          >
            Confirmar borrado
          </button>
        )}
      </div>

      <input
        type="text"
        placeholder="Buscar por nombre..."
        value={busqueda}
        onChange={(e) => setBusqueda(e.target.value)}
        style={{
          marginBottom: '15px',
          padding: '8px',
          width: '300px',
          borderRadius: '6px',
          border: '1px solid #ccc'
        }}
      />

      <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '20px' }}>
        <thead>
          <tr>
            {modoBorrado && <th></th>}
            <th>Nombre</th>
            <th>Teléfono</th>
            <th>Pago</th>
            <th>Notas</th>
            <th>Usuario</th>
            <th>Contraseña</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {personasFiltradas.map(p => (
            <tr key={p.id}>
              {modoBorrado && (
                <td>
                  <input
                    type="checkbox"
                    checked={seleccionados.includes(p.id)}
                    onChange={() => toggleSeleccionado(p.id)}
                  />
                </td>
              )}
              <td>{p.nombre}</td>
              <td>{p.telefono}</td>
              <td>{p.pagaMensualidad ? 'Sí' : 'No'}</td>
              <td>{p.notas}</td>
              <td>{usuarios.find(u => u.id === p.id)?.usuario || ''}</td>
              <td>{usuarios.find(u => u.id === p.id)?.contrasena || ''}</td>
              <td>
                <button className="boton-beige" onClick={() => editarFila(p.id)}>Editar</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default StudentsManagement;
