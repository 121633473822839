import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from './login';
import Calendar from './components/Calendar';
import StudentsManagement from './components/StudentsManagement';
import Navbar from './components/Navbar';
import AlumnoPanel from './components/AlumnoPanel';
import LandingPage from './components/LandingPage'; // ✅ nombre correcto

function App() {
  const [userType, setUserType] = useState(null);

  return (
    <Router>
      {userType === 'duenio' && <Navbar />}
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/login" element={<Login setUserType={setUserType} />} />
        <Route path="/alumno" element={
          userType === 'alumno'
            ? <AlumnoPanel />
            : <Navigate to="/" />
        } />
        <Route path="/calendario" element={
          userType === 'duenio' || userType === 'alumno'
            ? <Calendar />
            : <Navigate to="/" />
        } />
        <Route path="/personas" element={
          userType === 'duenio'
            ? <StudentsManagement />
            : <Navigate to="/" />
        } />
      </Routes>
    </Router>
  );
}

export default App;
