import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const LandingPage = () => {
const [registro, setRegistro] = useState({ estudio: '', email: '', telefono: '', nombreNegocio: '', contrasena: '' });
  const [loginDuenio, setLoginDuenio] = useState({ usuario: '', contrasena: '' });
  const [loginAlumno, setLoginAlumno] = useState({ usuario: '', contrasena: '' });
  const navigate = useNavigate();

  const registrarDemo = (e) => {
    e.preventDefault();
    fetch('/api/registro-demo', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(registro)
    }).then(res => res.json()).then(data => {
      if (data.redirect) navigate(data.redirect);
    });
  };

const handleLogin = (e, tipo) => {
  e.preventDefault();
  const endpoint = tipo === 'duenio' ? '/login' : '/login-alumno';
  const datos = tipo === 'duenio' ? loginDuenio : loginAlumno;

  fetch(endpoint, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ ...datos, studioId: 'default' }) // 👈 Agregamos studioId explícito
  })
    .then(res => {
      if (!res.ok) throw new Error('Login inválido');
      return res.json();
    })
    .then(data => {
      localStorage.setItem('nombre', data.nombre);
      localStorage.setItem('id', data.id);
      navigate(tipo === 'duenio' ? '/calendario' : '/alumno');
    })
    .catch(() => alert('Credenciales incorrectas'));
};

  return (
    <div className="min-h-screen bg-[#f5f1e6] flex flex-col md:flex-row">
      {/* Izquierda: Registro */}
      <div className="w-full md:w-1/2 p-10">
        <h2 className="text-2xl font-bold mb-6">Probar demo gratuita</h2>

<form onSubmit={registrarDemo} className="space-y-4">
<input
  className="w-full border border-gray-300 p-3 rounded"
  placeholder="Nombre del negocio"
  value={registro.nombreNegocio}
  onChange={(e) => setRegistro({ ...registro, nombreNegocio: e.target.value })}
/>
<input
  type="password"
  className="w-full border border-gray-300 p-3 rounded"
  placeholder="Crear contraseña"
  value={registro.contrasena}
  onChange={(e) => setRegistro({ ...registro, contrasena: e.target.value })}
/>

  <input
    className="w-full border border-gray-300 p-3 rounded"
    placeholder="Teléfono"
    value={registro.telefono}
    onChange={(e) => setRegistro({ ...registro, telefono: e.target.value })}
  />
  <input
    className="w-full border border-gray-300 p-3 rounded"
    placeholder="Email"
    type="email"
    value={registro.email}
    onChange={(e) => setRegistro({ ...registro, email: e.target.value })}
  />
  <button type="submit" className="bg-[#d8caa5] text-[#2c2c2c] font-bold px-6 py-3 rounded w-full">
    Probar demo
  </button>
</form>

      </div>

      {/* Derecha: Login */}
      <div className="w-full md:w-1/2 bg-white p-10">
        <h2 className="text-2xl font-bold mb-6">Ingresar</h2>

        <div className="mb-10">
          <h3 className="font-semibold mb-2">Dueño de estudio</h3>
          <form onSubmit={(e) => handleLogin(e, 'duenio')} className="space-y-3">
            <input
              className="w-full border border-gray-300 p-3 rounded"
              placeholder="Usuario"
              value={loginDuenio.usuario}
              onChange={(e) => setLoginDuenio({ ...loginDuenio, usuario: e.target.value })}
            />
            <input
              type="password"
              className="w-full border border-gray-300 p-3 rounded"
              placeholder="Contraseña"
              value={loginDuenio.contrasena}
              onChange={(e) => setLoginDuenio({ ...loginDuenio, contrasena: e.target.value })}
            />
            <button type="submit" className="bg-[#d8caa5] text-[#2c2c2c] font-bold px-6 py-3 rounded w-full">
              Ingresar como dueño
            </button>
          </form>
        </div>

        <div>
          <h3 className="font-semibold mb-2">Alumno</h3>
          <form onSubmit={(e) => handleLogin(e, 'alumno')} className="space-y-3">
            <input
              className="w-full border border-gray-300 p-3 rounded"
              placeholder="Usuario"
              value={loginAlumno.usuario}
              onChange={(e) => setLoginAlumno({ ...loginAlumno, usuario: e.target.value })}
            />
            <input
              type="password"
              className="w-full border border-gray-300 p-3 rounded"
              placeholder="Contraseña"
              value={loginAlumno.contrasena}
              onChange={(e) => setLoginAlumno({ ...loginAlumno, contrasena: e.target.value })}
            />
            <button type="submit" className="bg-[#d8caa5] text-[#2c2c2c] font-bold px-6 py-3 rounded w-full">
              Ingresar como alumno
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
